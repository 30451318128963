<template>
  <div >
    <bread-crumb  :name="shops.length>0 ? shops[0].category.name: 'No Shops in the selected category'"></bread-crumb>
    <section class="ps-store-list">
      <div class="ps-container">
        <div class="ps-section__header"></div>
        <div class="ps-section__wrapper">
          <div class="ps-section__left">
            <aside class="widget widget--vendor">
              <h3 class="widget-title">Search</h3>
              <input class="form-control" type="text" placeholder="Search..." />
            </aside>
            <aside class="widget widget--vendor">
              <h3 class="widget-title">Filter by Category</h3>
              <div class="form-group">
                <select class="form-control">
                  <option value="0" selected="selected">
                    Filter by shop category
                  </option>
                </select>
              </div>
            </aside>
            <aside class="widget widget--vendor">
              <h3 class="widget-title">Filter by Location</h3>
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Search by Town"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Search by City"
                />
              </div>
            </aside>
          </div>
          <div class="ps-section__right">
            <section class="ps-store-box">
              
              <div v-if="shops.length>0" class="ps-section__content">
                <div class="row">
                  <div v-for="shop in shops" :key="shop.id" class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <shop-card :shop="shop"></shop-card>
                  </div>
                 
                </div>
                <div class="ps-pagination">
                  <ul class="pagination">
                    <li class="active"><a href="#">1</a></li>
                    <li><a href="#">2</a></li>
                    <li><a href="#">3</a></li>
                    <li>
                      <a href="#"
                        >Next Page<i class="icon-chevron-right"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div v-else class="ps-section__header">
                <div class="ps-block__header">
                  <h3>Currently no shops in this category</h3>
                  <hr />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import global from "@/mixins/global.js";
import ShopCard from "@/components/Cards/ShopCard";
import BreadCrumb from'@/components/Navs/BreadCrumb';
import axios from "axios";
export default {
  components: { ShopCard,BreadCrumb },
  data() {
    return {
      shops: [],
      
    };
  },
  mixins: [global],
  mounted() {
    this.getShops();
  },
  methods: {
    getShops() {
      axios.get(`shops?category_slug=${this.$route.params.slug}`).then((response) => {
        this.shops = response.data.data;
      });
    },
  },
};
</script>

<style lang="css" scoped>
.main-banner {
  height: 200px;
}
.search-input {
  width: 100%;
}
.btn-warning {
  width: 200px;
}
</style>